<template>
  <div>
    <Dialog :visible="ativo" :closable="false" :modal="true" :contentStyle="{overflow: 'visible', width: '150px', height:'150px'}" >
      <div class="flex flex-column">
        <div class="flex align-items-center justify-content-center">
          <h4>Aguarde</h4>
        </div>
        <div class="flex align-items-center justify-content-center">
          <img
            src="../../public/load100spede2.gif"
            class="mr-1"
            style="cursor: -webkit-grabbing; cursor: grabbing"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
export default {
  computed: {
      ativo() {
        return this.$store.state.dlgLoading
      },
    },
}
</script>

<style>
</style>
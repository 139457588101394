export default {
    "application/pdf": { ext: "pdf" },
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": { ext: "docx" },
    "application/msword": { ext: "doc" },
    "application/vnd.ms-excel": { ext: "xls" },
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": { ext: "xlsx" },
    "text/plain": { ext: "txt" },
    "application/vnd.oasis.opendocument.text": { ext: "odt" },
    "image/tiff": { ext: "tiff" },
    "image/jpeg": { ext: "jpeg" },
    "image/jpg": { ext: "jpg" },
    "image/png": { ext: "png" },
    "image/bmp": { ext: "bmp" },
    "application/rar": { ext: "rar" },
    "application/zip": { ext: "zip" },
    "application/vnd.ms-word.document.macroEnabled.12": { ext: "docm" }
}

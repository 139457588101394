export default {
    "NAO_DEFINIDO": { code: 0, descricao: "" },
    "DOCUMENTO": {code: 1, descricao: "Documento" },
    "MODELO_DOCUMENTO": { code: 2, descricao: "Modelo de Documento" },
    "PROTOCOLO": { code: 3, descricao: "Protocolo" },
    "CAPA_PROCESSO": {code: 4, descricao: "Capa de Processo" },
    "MANIFESTACAO": { code: 5, descricao: "Manifestação" },
    "IMAGEMMAN": {code: 6, descricao: "Imagem de Manifestação" },
    "VIDEO":{ code: 7, descricao: "Vídeo" },
    "ITEM_PROTOCOLO":{ code: 8, descricao: "Item de Protocolo" },
    "DOCUMENTO_SEM_EFEITO":{ code: 9, descricao: "Documento sem Efeito" }
}
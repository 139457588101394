<template>
  <div v-if="escritoAtual">
    <div>
    <div class="fluid formgrid grid col-12 mt-4">
        <div class="field col-4 md:col-4">
            <b>
            <span v-if="escritoAtual.tipoEscrito=='PROCESSO'">
                {{ "PROCESSO N° : "+ escritoAtual.codigoEscrito}}
            </span>
            <span v-if="escritoAtual.tipoEscrito=='DOCUMENTO'">
                {{ "DOCUMENTO N°: " + escritoAtual.codigoEscrito}}
            </span>
            <span v-if="escritoAtual.tipoEscrito=='RASCUNHO'">
                {{ "RASCUNHO N°: " + escritoAtual.codigoEscrito}}
            </span>
            <span v-if="escritoAtual.tipoEscrito=='MANIFESTACAO'">
                {{ "MANIFESTAÇÃO N°: " + escritoAtual.codigoEscrito}}
            </span>
            </b>
        </div>
        <div class="field col-4 md:col-4">
            DATA: &nbsp;
            <span>{{
                escritoAtual ? $formatarDataHora(escritoAtual.dataCriacao) : ''
            }}</span>
        </div>
        <div class="field col-4 md:col-4">
            SITUAÇÃO: &nbsp;
            <span>{{
                escritoAtual ? escritoAtual.situacaoEscrito : ''
            }}</span>
        </div>
        <div class="field col-12 md:col-12">
            <span v-if="escritoAtual.tipoEscrito=='PROCESSO'">
                {{ "OBJETO: "+ escritoAtual.objeto.toUpperCase()}}
            </span>
            <span v-else>
                {{ "DESCRIÇÃO: "+ (escritoAtual.descricao.length>200? escritoAtual.descricao.substring(1,200).toUpperCase():escritoAtual.descricao.toUpperCase())}}
            </span>
        </div>
    </div>
    <TabView class="tabview" @tab-change ="changeTabDetalhe"
          :key="tvDetalheKey" :activeIndex="tvDetalheIndex">
        <!-- DADOS GERAIS -->
        <TabPanel>
            <template #header>
            <div>
              <i class="fas fa-bars mr-2"></i>
              <span class="texto-tabview" style="width:100px;">Dados Gerais</span>
            </div>
            </template>
        </TabPanel>
        <!-- JULGAMENTO -->
        <TabPanel :disabled="!(escritoAtual.tipoEscrito=='PROCESSO')">
          <template #header>
            <div>
              <i class="fas fa-users mr-2"></i>
              <span class="texto-tabview">Julgamento</span>
            </div>
          </template>
        </TabPanel>
        <!-- INTERESSADOS -->
        <TabPanel v-if="!usuarioExterno" :disabled="!(escritoAtual.tipoEscrito=='PROCESSO')">
          <template #header>
            <div>
              <i class="fas fa-users mr-2"></i>
              <span class="texto-tabview">Interessados</span>
            </div>
          </template>
        </TabPanel>
        <!-- ASSINATURAS -->
        <TabPanel v-if="!usuarioExterno" :disabled="escritoAtual.tipoEscrito!='DOCUMENTO'">
          <template #header>
            <i class="fas fa-pen-fancy mr-2"></i>
            <span class="texto-tabview">Assinaturas</span>
          </template>
        </TabPanel>
        <!-- VISTOS -->
        <TabPanel v-if="!usuarioExterno">
          <template #header>
            <i class="fas fa-pen-fancy mr-2"></i>
            <span class="texto-tabview">Vistos</span>
          </template>
        </TabPanel>
        <!-- COMENTARIOS -->
        <TabPanel v-if="!usuarioExterno">
          <template #header>
            <div>
              <i class="fas fa-comment-dots mr-2"></i>
              <span class="texto-tabview">Comentários</span>
            </div>
          </template>
        </TabPanel>
        <!-- TRAMITACAO -->
        <TabPanel v-if="!usuarioExterno">
          <template #header>
            <div>
              <i class="fas fa-exchange-alt mr-2"></i>
              <span class="texto-tabview">Tramitação</span>
            </div>
          </template>
        </TabPanel>
        <!-- HISTORICO -->
        <TabPanel v-if="!usuarioExterno">
          <template #header>
            <div>
              <i class="fas fa-history mr-2"></i>
              <span class="texto-tabview">Histórico</span>
            </div>
          </template>
        </TabPanel>
      </TabView>
      <div class="mr-3 ml-3">
      <!-- DADOS GERAIS CONTEUDO INICIO -->
      <div v-if="tvDetalheIndex==0">
        <div>
            <div class="fluid formgrid grid col-12">
                <div class="field col-12 md:col-12">
                    ÓRGÃO: &nbsp;
                    <span>{{escritoAtual.pessoaJuridicaOrigem.nome.toUpperCase()}}</span>
                </div>
            </div>
            <div class="fluid formgrid grid col-12">
                <div class="field col-12 md:col-6">
                    <span v-if="escritoAtual.tipoEscrito=='PROCESSO'">
                        {{ "NATUREZA: "+ escritoAtual.natureza.descricao.toUpperCase()}}
                    </span>
                    <span v-if="escritoAtual.tipoEscrito=='DOCUMENTO' || escritoAtual.tipoEscrito=='RASCUNHO'">
                        {{ "ASSUNTO: "+ escritoAtual.assuntoDocumento.descricao.toUpperCase()}}
                    </span>
                    <span v-if="escritoAtual.tipoEscrito=='MANIFESTACAO'">
                        {{ "ASSUNTO: "+ escritoAtual.assuntoManifestacao.descricao.toUpperCase()}}
                    </span>
                </div>
                <div class="field col-12 md:col-6">
                    <span v-if="escritoAtual.tipoEscrito=='PROCESSO' && escritoAtual.especie">
                        {{ "ESPÉCIE: "+ escritoAtual.especie.descricao.toUpperCase()}}
                    </span>
                    <span v-if="escritoAtual.tipoEscrito=='DOCUMENTO'  || escritoAtual.tipoEscrito=='RASCUNHO'">
                        {{ "TIPO: "+ escritoAtual.tipoDocumento.descricao.toUpperCase()}}
                    </span>
                </div>
            </div>
            <div class="fluid formgrid grid col-12" v-if="escritoAtual.tipoEscrito=='PROCESSO'">
                <div class="field col-12 md:col-6">
                    <span>
                        {{ "RELATOR: "+ (escritoAtual.relatorTipoDescricao?escritoAtual.relatorTipoDescricao.toUpperCase():'')}}
                    </span>
                </div>
                <div class="field col-12 md:col-6"  v-if="escritoAtual.tipoEscrito=='PROCESSO'">
                    <span>
                        {{ "PROCURADOR: "+ (escritoAtual.procurador && escritoAtual.procurador.nome?escritoAtual.procurador.nome.toUpperCase():'')}}
                    </span>
                </div>
            </div>
            <div class="fluid formgrid grid col-12" v-if="escritoAtual.tipoEscrito=='PROCESSO'">
                <div class="field col-12 md:col-6">
                    <span>
                        {{ "MUNICÍPIO: "+ (escritoAtual.municipio?escritoAtual.municipio.municipio.descricao.toUpperCase():'')}}
                    </span>
                </div>
                <div class="field col-12 md:col-6">
                    <span>
                        {{ "EXERCÍCIO: "+ escritoAtual.exercicioReferente}}
                    </span>
                </div>
            </div>
            <div class="fluid formgrid grid col-12" v-if="escritoAtual.tipoEscrito=='DOCUMENTO' && escritoAtual.midiaFisica">
                <div class="field col-12 md:col-12">
                    <span>
                        {{ "MÍDIA FÍSICA: "+ escritoAtual.midiaFisica.localizacaoFisica.toUpperCase()}}
                    </span>
                </div>
            </div>
            <div class="fluid formgrid grid col-12">
                <div class="field col-12 md:col-12">
                    <span>
                        {{ "PRIORIDADE: "+ escritoAtual.prioridade.descricao.toUpperCase()}}
                    </span>
                </div>
            </div>
        </div>
      </div>
      <!-- DADOS GERAIS CONTEUDO FIM -->
      <!-- JULGAMENTO CONTEUDO INICIO-->
      <div v-if="tvDetalheIndex==1">
          <h5>Julgamento:</h5>
          <DataTable
            :value="dataJulgamento"
            v-model:selection="selectDataJulgamento"
            dataKey="id"
            selectionMode="single"
          >
            <Column field="sessao" header="Sessão">
              <template #body="slotProps">
                <span v-tooltip.bottom="slotProps.data.sessao">
                  {{ slotProps.data.sessao }}
                </span>
              </template>
            </Column>
            <Column field="relator" header="Relator">
              <template #body="slotProps">
                <span v-tooltip.bottom="slotProps.data.relator">
                  {{ slotProps.data.relator }}
                </span>
              </template>
            </Column>
            <Column field="procurador" header="Procurador">
              <template #body="slotProps">
                <span v-tooltip.bottom="slotProps.data.procurador">
                  {{ slotProps.data.procurador }}
                </span>
              </template>
            </Column>
            <Column field="situacao" header="Situação"/>
            <Column field="dataPublicacao" header="Data Publicação Decisão">
              <template #body="slotProps">
                <span>{{ slotProps.data.dataPublicacao? $formatarData(slotProps.data.dataPublicacao): "" }}</span>
              </template>
            </Column>
            <template #empty>Nenhum registro encontrado :(</template>
          </DataTable>
          <br/>
          <div class="p-field p-col-12" style="font-weight: bold;">
            <div class="p-field p-col-12" style="padding: inherit !important;">
                <label>Destaques:</label>
            </div>
            <div class=" flex p-col-12">
                <Textarea style="width:100%" v-model="txtItemJulgamentoDestaques" rows="15"  disabled/>
            </div>
          </div>
      </div>
      <!-- JULGAMENTO CONTEUDO FIM-->
      <!-- INTERESSADOS CONTEUDO INICIO -->
      <div  v-if="tvDetalheIndex==2">
          <DataTable
            :value="dataInteressado"
            :resizableColumns="true"
            columnResizeMode="fit"
            class="datatable-responsive"
            :loading="loading"
            :selection="selectDataInteressado"
            selectionMode="single"
            :scrollable="true"
            scrollHeight="150px"
            data-key="id"
          >
            <Column field="tipoInteressado" header="Tipo">
              <template #body="slotProps">
                <span v-tooltip.bottom="slotProps.data.tipoInteressado" >{{
                  slotProps.data.tipoInteressado
                    .toString()
                    .toLowerCase()
                    .replace(/_/g, " ")
                    .replace(/^\w/, (c) => c.toUpperCase())
                }}</span>
              </template>
            </Column>
            <Column field="nome" header="Nome">
              <template #body="slotProps">
                <span v-tooltip.bottom="slotProps.data.nome">{{
                  slotProps.data.nome
                }}</span>
              </template>
            </Column>
            <Column field="papelInteressado" header="Papel">
              <template #body="slotProps">
                <span v-tooltip.bottom="slotProps.data.papelInteressado">{{
                  slotProps.data.papelInteressado
                }}</span>
              </template>
            </Column>
            <Column field="dataInicioInteresse" header="Dt.Inico">
              <template #body="slotProps">
                <span v-tooltip.bottom="$formatarDataHora(slotProps.data.dataInicioInteresse)">{{
                  $formatarDataHora(slotProps.data.dataInicioInteresse)
                }}</span>
              </template>
            </Column>
            <Column field="dataTerminoInteresse" header="Dt.Término">
                <template #body="slotProps">
                <span v-tooltip.bottom="$formatarDataHora(slotProps.data.dataTerminoInteresse)">{{
                  $formatarDataHora(slotProps.data.dataTerminoInteresse)
                }}</span>
              </template>
            </Column>
            <Column field="numeroCpfCnpj" header="CPF/CNPJ" />
            <Column field="numeroRegistroProfissional" header="Número da OAB" />
            <Column field="enderecoCompleto" header="Endereço" >
              <template #body="slotProps">
                <span v-tooltip.bottom="slotProps.data.enderecoCompleto">{{
                  slotProps.data.enderecoCompleto
                }}</span>
              </template>
            </Column>
            <template #empty>Nenhum registro encontrado :(</template>
          </DataTable>
      </div>
      <!-- INTERESSADOS CONTEUDO FIM -->
      <!-- ASSINATURAS CONTEUDO INICIO -->
      <div  v-if="tvDetalheIndex==3">
          <DataTable
            :value="dataAssinatura"
            :resizableColumns="true"
            columnResizeMode="fit"
            class="datatable-responsive mt-3 mb-2"
            :loading="loading"
            :scrollable="true"
            scrollHeight="150px"
            :lazy="true"
            data-key="id"
          >
            <Column field="dataHora" header="Data/Hora">
              <template #body="slotProps">
                <span>{{ $formatarDataHora(slotProps.data.dataCriacao) }}</span>
              </template>
            </Column>
            <Column field="responsavel" header="Responsável" />
            <Column field="tipo" header="Tipo" />
            <Column header="Eletrônica?" >
              <template #body="slotProps">
                  <span>{{ slotProps.data.eletronica? "Sim": "Não" }}</span>
                </template>
            </Column>
            <template #empty>Nenhum registro encontrado :(</template>
          </DataTable>
      </div>
      <!-- ASSINATURAS CONTEUDO FIM -->
      <!-- VISTOS CONTEUDO INICIO -->
      <div  v-if="tvDetalheIndex==4">
          <DataTable
            :value="dataVisto"
            :resizableColumns="true"
            columnResizeMode="fit"
            class="datatable-responsive mt-3 mb-2"
            :loading="loading"
            :scrollable="true"
            scrollHeight="150px"
            :lazy="true"
            data-key="id"
          >
            <Column field="dataHora" header="Data/Hora">
              <template #body="slotProps">
                <span>{{ $formatarDataHora(slotProps.data.dataHora) }}</span>
              </template>
            </Column>
            <Column field="responsavel" header="Responsável" />
            <template #empty>Nenhum registro encontrado :(</template>
          </DataTable>
      </div>
      <!-- VISTOS CONTEUDO FIM -->
      <!-- COMENTARIOS CONTEUDO INICIO -->
      <div v-if="tvDetalheIndex==5">
          <DataTable
            :value="dataComentario"
            :resizableColumns="true"
            columnResizeMode="fit"
            class="datatable-responsive"
            :loading="loading"
            :selection="selectDataComentario"
            selectionMode="single"
            :scrollable="true"
            scrollHeight="350px"
            :lazy="true"
            data-key="id"
          >
            <Column field="dataHora" header="Data/Hora">
              <template #body="slotProps">
                <span>{{ $formatarDataHora(slotProps.data.dataHora) }}</span>
              </template>
            </Column>
            <Column field="autor" header="Autor" />
            <Column field="comentario" header="Comentário">
                <template #body="slotProps">
                  <span v-tooltip.left="slotProps.data.comentario">
                    {{ slotProps.data.comentario }}
                  </span>
                </template>
              </Column>

            <template #empty>Nenhum registro encontrado :(</template>
          </DataTable>
      </div>
      <!-- COMENTARIOS CONTEUDO FIM -->
      <!-- TRAMITACAO CONTEUDO INICIO -->
      <div v-if="tvDetalheIndex==6">
            <DataTable
              :value="dataTramitacao"
              scrollHeight="400px"
              :resizableColumns="true"
              columnResizeMode="fit"
              class="datatable-responsive"
              :loading="loading"
              :selection="selectDataTramitacao"
              selectionMode="single"
              :lazy="true"
              responsiveLayout="scroll"
              dataKey="id"
            >
              <Column field="codigo" header="Código" />
              <Column field="envio" header="Envio">
                <template #body="slotProps">
                  <span>{{ $formatarDataHora(slotProps.data.envio) }}</span>
                </template>
              </Column>
              <Column field="remetente" header="Remetente" bodyStyle="max-width:10rem">
                <template #body="slotProps">
                  <span v-tooltip.bottom="slotProps.data.remetente">{{
                    slotProps.data.remetente
                  }}</span>
                </template>
              </Column>
              <Column field="destinatario" header="Destinatário" bodyStyle="max-width:10rem">
                <template #body="slotProps">
                  <span v-tooltip.bottom="slotProps.data.destinatario">{{
                    slotProps.data.destinatario
                  }}</span>
                </template>
              </Column>
              <Column field="motivo" header="Motivo">
                <template #body="slotProps">
                  <span v-tooltip.bottom="slotProps.data.motivo">{{
                    slotProps.data.motivo
                  }}</span>
                </template>
              </Column>
              <Column field="recebimento" header="Recebimento">
                <template #body="slotProps">
                  <span v-if="slotProps.data.recebimento != null">{{
                    $formatarDataHora(slotProps.data.recebimento)
                  }}</span>
                </template>
              </Column>
              <Column field="situacao" header="Situação"/>
              <Column field="observacao" header="Observação" bodyStyle="max-width:10rem">
                <template #body="slotProps">
                  <span v-tooltip.up="slotProps.data.detalhamentoObservacao">
                    {{ slotProps.data.detalhamentoObservacao }}
                  </span>
                </template>
              </Column>
              <Column field="recusa" header="Recusa" >
                <template #body="slotProps">
                  <span v-tooltip.bottom="slotProps.data.recusa">
                    {{ slotProps.data.recusa }}
                  </span>
                </template>
              </Column>
              <Column field="detalhamentoObservacaoRecusa" header="Obs. Recusa" >
                <template #body="slotProps">
                  <span v-tooltip.up="slotProps.data.detalhamentoObservacaoRecusa">
                    {{ slotProps.data.detalhamentoObservacaoRecusa }}
                  </span>
                </template>
              </Column>
              <template #empty>Nenhum registro encontrado :(</template>
            </DataTable>
      </div>
      <!-- TRAMITACAO CONTEUDO FIM -->
      <!-- HISTORICO CONTEUDO INICIO -->
      <div v-if="tvDetalheIndex==7">
            <DataTable
              :value="dataHistorico"
              :resizableColumns="true"
              columnResizeMode="fit"
              class="p-datatable-responsive"
              :loading="loading"
              :scrollable="true"
              scrollHeight="350px"
              :lazy="true"
              data-key="id"
            >
              <Column field="data" header="Data/Hora" bodyStyle="width: 5rem">
                <template #body="slotProps">
                  <span>{{ $formatarDataHora(slotProps.data.data) }}</span>
                </template>
              </Column>
              <Column field="descricao" header="Descrição" >
                <template #body="slotProps">
                  <span v-tooltip.left="slotProps.data.descricao">
                    {{ slotProps.data.descricao }}
                  </span>
                </template>
              </Column>
              <Column field="observacao" header="Observação" >
                <template #body="slotProps">
                  <span v-tooltip.left="slotProps.data.observacao">
                    {{ slotProps.data.observacao }}
                  </span>
                </template>
                </Column>
              <template #empty>Nenhum registro encontrado :(</template>
            </DataTable>
      </div>
      <!-- HISTORICO CONTEUDO FIM -->
      </div>
    </div>
  </div>

</template>


<script>
import SpedeService from "@/service/SpedeService";

export default {
  props: ['idEscrito', 'externo'],
  data() {
    return {
      txtItemJulgamentoDestaques: null,
      dataJulgamento: null,
      selectDataJulgamento: null,
      dataComentario: null,
      dataInteressado: null,
      dataAssinatura: null,
      dataVisto: null,
      dataTramitacao: null,
      dataHistorico: null,
      dataHistoricoInativo: null,
      selectDataInteressado: null,
      selectDataComentario: null,
      selectDataTramitacao: null,
      tvDetalheKey: 1,
      tvDetalheIndex: 0,
      tvDetalheIndexUpdate: -1,
      spedeService: null,
      loading: false,
      escritoAtual: null,
      idEscritoAtual: null,
      usuarioExterno: false,
    };
  },
  watch: {
    selectDataJulgamento(julgamento) {
      this.txtItemJulgamentoDestaques = julgamento.destaques;
    }
  },
  mounted(){
  },
  created() {
    this.usuarioExterno = this.externo;
    this.idEscritoAtual = this.idEscrito;
    this.spedeService = new SpedeService();
    this.carregaDadosEscrito();
  },
  methods: {
    carregaDadosEscrito(){
        this.spedeService
        .getEscrito(this.idEscritoAtual)
        .then((data) => {
          this.escritoAtual = data;
          if (this.escritoAtual.tipoEscrito =='DOCUMENTO' && !this.usuarioExterno){
            this.spedeService.getMidiaFisicaDocumento(this.idEscritoAtual).then((data) => {
                    this.escritoAtual.midiaFisica = data;
            });
          }
      });
    },
    changeTabDetalhe(event){
      this.tvDetalheIndex = event.index;
      switch (event.index) {
        case 1:
            this.clickAbaJulgamento();
            break;
        case 2:
            this.clickAbaInteressado();
            break;
        case 3:
            this.clickAbaAssinatura();
            break;
        case 4:
            this.clickAbaVisto();
            break;
        case 5:
            this.clickAbaComentario();
            break;
        case 6:
            this.clickAbaTramitacao();
            break;
        case 7:
            this.clickAbaHistorico();
            break;
      }

    },
    clickAbaInteressado() {
      this.loading = true;
      this.spedeService
          .getInteressados(this.idEscritoAtual)
          .then((data) => {
            this.dataInteressado = data;
            this.loading = false;
      });
    },
    clickAbaComentario() {
      this.loading = true;
      this.spedeService
          .getComentarios(this.idEscritoAtual)
          .then((data) => {
            this.dataComentario = data;
            this.loading = false;
      });
    },
    clickAbaAssinatura() {
      this.loading = true;
      this.spedeService
          .getAssinaturasDoc(this.idEscritoAtual)
          .then((data) => {
              this.dataAssinatura = data;
              this.loading = false;
        });
    },
    clickAbaVisto() {
      this.loading = true;
      this.spedeService
          .getVistos(this.idEscritoAtual)
          .then((data) => {
              this.dataVisto = data;
              this.loading = false;
      });
    },
    clickAbaJulgamento() {
      this.loading = true;
      this.spedeService.getJulgamento(this.idEscritoAtual).then((data) => {
          this.dataJulgamento = data;
          this.loading = false;
      });
    },
    clickAbaTramitacao() {
      this.loading = true;
      this.spedeService
          .getTramitacao(this.idEscritoAtual)
          .then((data) => {
              this.dataTramitacao = data;
              this.loading = false;
      });
    },
    clickAbaHistorico() {
      this.loading = true;
      this.spedeService
          .getHistorico(this.idEscritoAtual)
          .then((data) => {
              this.dataHistorico = data;
              this.loading = false;
      });
    },
  },
};
</script>